import React from "react"
import * as BlockContent from "@sanity/block-content-to-react"

//custom BlockRenderer includes empty line when text brake
const BlockRenderer = props => {
    if (props.children.includes("")) {
      return <p>&nbsp;</p>
    }
    return BlockContent.defaultSerializers.types.block(props)
}

export default BlockRenderer